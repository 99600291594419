import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Grid } from '@mui/material';
import { insertDentPrice, insertDentType, setDentAsNotConfirmed, updateTeeth, } from '../../../../redux/slices/prothese/protheseSlice';
import { dispatch, useSelector } from '../../../../redux/store';
import EMPTY from '../../../../assets/illustrations/illustration_empty_content.svg';
export default function LeftOptionSections({ selectedDent, types, setSelectedType, selectedType, setCurrentPrice, }) {
    const data = useSelector((state) => state.type.allTypes);
    return (_jsx(Grid, { container: true, sx: {
            marginTop: '15px',
            background: '#F8FAFF',
            padding: '23px 32px',
            borderRadius: 3,
        }, gap: 2, children: data?.length > 0 ? (data?.map((el) => {
            return (_jsx(Grid, { item: true, md: 11.5, lg: 5.7, xl: 2.8, sx: {
                    fontWeight: 600,
                    fontSize: '14px',
                    padding: '10px 20px',
                    cursor: 'pointer',
                    whiteSpace: 'wrap',
                    textAlign: 'center',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: selectedType === el?._id ? 'white' : 'black',
                    border: selectedType === el?._id ? '1px solid #9DD1F4' : '1px solid #E1E4ED',
                    background: selectedType === el?._id
                        ? 'linear-gradient(270.5deg, #2788E7 0%, #5EC4E0 107.92%), #B4B9C9'
                        : '#F1F3F7',
                    boxShadow: selectedType === el?._id ? '0px 3px 0px #9DD1F4' : 'none',
                    transition: 'all .3s ease',
                }, onClick: () => {
                    dispatch(insertDentPrice({ name: selectedDent.toString(), price: Number(el?.price) }));
                    setSelectedType(el?._id);
                    setCurrentPrice(el?.price);
                    dispatch(insertDentType({ name: selectedDent.toString(), types: el?._id }));
                    dispatch(setDentAsNotConfirmed({ name: selectedDent.toString() }));
                    dispatch(updateTeeth());
                }, children: el?.code }, el.code));
        })) : (_jsx(Box, { sx: {
                padding: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }, children: _jsx("img", { src: EMPTY, alt: "error" }) })) }));
}
